import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import logo from "../../assets/images/logo.png";
import logo_mobile from "../../assets/images/logo_mobile.png";
import { useUserStore } from "../../store/userStore";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../ConfirmModal";
import { performLogout } from "../../utils/utilityFunctions";

function Header(props) {
  const { currentPage } = props;
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();
  const jwtData = useUserStore((state) => state.jwtData);
  const loginLogoutAction = (e) => {
    e.preventDefault();
    if (jwtData) setShowLogoutConfirm(true);
    // else navigate("/signin"); -> staging
    else if (currentPage === "login") navigate("/practitioner-signup");
    else navigate("/practitioner-login");
  };

  const [hamburgerStatus, setHamburgerStatus] = useState(false);
  const hamburgerClickHandler = () => {
    setHamburgerStatus(!hamburgerStatus);
  };
  console.log('process.env.REACT_APP_ENVIRONMENT::',process.env.REACT_APP_ENVIRONMENT);

  return (
    <>
      <div className="header ">
        <div className="flex justify-between flex-1">
          <div>
            <Link to="/">
              <img id="logo" src={logo}></img>
              <img id="logo_mobile" src={logo_mobile}></img>
            </Link>
          </div>
          <div id="hamburger" onClick={hamburgerClickHandler}>
            <div id="menuToggle">
              <input type="checkbox" />
              <span></span>
              <span></span>
              <span></span>
              <div></div>
            </div>
          </div>
        </div>
        <nav className={hamburgerStatus ? "nav-open" : ""}>
          <ul>
            {currentPage === "home" && (
              <li>
                <Link to={"/practitioner-signup"}>Practitioner Signup</Link>
              </li>
            )}
            {jwtData && (
              <li>
                <Link to={"/practitioner/dashboard"}>Dashboard</Link>
              </li>
            )}
            {
              process.env.REACT_APP_ENVIRONMENT === "staging" && (
                <li>
                  <Link to={"/browse-practitioners"}>Browse Practitioners</Link>
                </li>
              )
            }
            {
              process.env.REACT_APP_ENVIRONMENT === "staging" && (
                <li>
                  <Link to={"/get-matched"}>Get Matched</Link>
                </li>
              )
            }
            <li>
              <a href={""} onClick={loginLogoutAction}>
                {jwtData
                  ? "Logout"
                  : currentPage === "login"
                    ? "Sign Up"
                    : "Login"}
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <ConfirmModal
        show={showLogoutConfirm}
        setShow={setShowLogoutConfirm}
        title="Logout"
        message="Are you sure you want to logout?"
        onConfirm={() => {
          performLogout();
        }}
        onCancel={() => {}}
      ></ConfirmModal>
    </>
  );
}

export default Header;
