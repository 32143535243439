import icon_list from "../../assets/images/icon_list.png";
import icon_grid from "../../assets/images/icon_grid.png";
import Select from "react-select";
import { NameFilter, TypeFilter } from "./forms/form";
import useBrowsePractitioner from "../../hooks/useBrowsePractitioner";
import { usePractitionerStore } from "../../store/practitionerStore";
import { useEffect, useState } from "react";
import { cleanObject } from "../../utils/utilityFunctions";
import {
  sortOptions,
  specialtyOptions,
  genderOptions,
  meetingOptions,
  costOptions,
} from "../../utils/globals";

function PractitionersFilter({ onChange }) {
  const doFetch = usePractitionerStore((state) => state.doFetch);

  const [filters, setFilterObject] = useState({});
  const setFilter = (state) => {
    state = cleanObject(state);
    setFilterObject(state);
  };
  const [sort, setSort] = useState({});

  useEffect(() => {
    const getResult = async () => {
      await doFetch(filters, sort);
    };
    getResult();
  }, [filters, sort]);

  const { mode, setMode, setName } = useBrowsePractitioner({ onChange });

  return (
    <div className="practitioner-filter">
      <div className="left">
        <div className="filters">
          <div className="labels">Filter by </div>

          <NameFilter
            names={sortOptions}
            classNamePrefix="practitioner-select"
            onChange={async (e) => {
              try {
                setSort({ ...sort, firstname: e.value });
              } catch (error) {
                console.log(error.message);
              }
            }}
          />

          {/* <div className="labels">Type</div>
          <div className="selects">
            <Select
              options={types}
              classNamePrefix="practitioner-select"
              defaultValue={types.find(({ value }) => value === "Any")}
            />
          </div> */}

          <div className="labels">Specialty</div>
          <div className="selects">
            <Select
              menuPlacement="auto"
              menuPosition="fixed"
              options={specialtyOptions}
              classNamePrefix="practitioner-select"
              defaultValue={specialtyOptions.find(
                ({ value }) => value === "Any"
              )}
              onChange={async (e) => {
                try {
                  setFilter({ ...filters, specialties: e.value });
                } catch (error) {
                  console.log(error.message);
                }
              }}
            />
          </div>

          <div className="labels">Gender</div>
          <div className="selects">
            <Select
              options={genderOptions}
              classNamePrefix="practitioner-select"
              defaultValue={genderOptions.find(({ value }) => value === "Any")}
              onChange={async (e) => {
                try {
                  setFilter({ ...filters, gender: e.value });
                } catch (error) {
                  console.log(error.message);
                }
              }}
            />
          </div>

          <div className="labels">Meeting</div>
            <div className="selects">
              <Select
                options={meetingOptions}
                classNamePrefix="practitioner-select"
                defaultValue="Online"
                onChange={async (e) => {
                  try {
                    setFilter({ ...filters, meeting: e.value });
                  } catch (error) {
                    console.log(error.message);
                  }
                }}
              />
            </div>

            <div className="labels">Cost</div>
            <div className="selects">
              <Select
                options={costOptions}
                classNamePrefix="practitioner-select"
                defaultValue="Online"
              />
            </div>

        </div>
      </div>
      <div className="display-mode right d-flex justify-content-end align-items-center">
        <div>Display</div>
        <div
          onClick={() => setMode("list")}
          className={
            mode == "list" ? "icons icon_list active" : "icons icon_list"
          }
        >
          <img src={icon_list}></img>
        </div>
        <div
          onClick={() => setMode("grid")}
          className={
            mode == "grid" ? "icons icon_list active" : "icons icon_list"
          }
        >
          <img src={icon_grid}></img>
        </div>
      </div>
    </div>
  );
}

export default PractitionersFilter;
