import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainContainer from "../containers/MainContainer";
import ClientLogin from "../containers/ClientLogin";
import ClientSignUp from "../containers/ClientSignUp";
import AdminLogin from "../containers/AdminLogin";
import ErrorPage from "../containers/ErrorPage";
import GetStarted from "../containers/GetStarted";
import HomePage from "../containers/HomePage/";
import PractitionerLogin from "../containers/PractitionerLogin";
import Practitioners from "../containers/Practitioners";
import PractitionerSignUp from "../containers/PractitionerSignUp";
import Dashboard from "../containers/Dashboard";
import ClientDashboardHome from "../containers/ClientDashboardHome";
import ClientBookCalendar from "../containers/ClientBookCalendar";
import ClientMessages from "../containers/ClientMessages";
import PractitionerDashboardHome from "../containers/PractitionerDashboardHome";
import PractitionerCalendar from "../containers/PractitionerCalendar";
import PractitionerProfile from "../containers/PractitionerProfile";
import PractitionerCerts from "../containers/PractitionerCerts";
import PractitionerProfilePage from "../containers/PractitionerProfilePage";
import AdminDashboardHome from "../containers/AdminDashboardHome";
import CoachSettings from "../containers/CoachSetting";
import AdminDashboardApproval from "../containers/AdminDashboardApproval";
import AdminDashboardDocumentApproval from "../containers/AdminDashboardDocumentApproval";
import AdminPractitionerList from "../containers/AdminPractitionerList";
import AdminUserList from "../containers/AdminUserList";
import AdminDashboardPractitionerProfile from "../containers/AdminDashboardPractitionerProfile";
import PractitionerSettings from "../containers/PractitionerSettings";
import PractitionerMessages from "../containers/PractitionerMessages";
import PractitionerClients from "../containers/PractitionerClients";
import ClientSessions from "../containers/ClientSessions";
import ClientProfile from "../containers/ClientProfile";
import Faq from "../containers/Faq";
import BookPractitioner from "../containers/BookPractitioner";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainContainer component={HomePage} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "admin/signin",
    element: <MainContainer component={AdminLogin} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/signin",
    element: <MainContainer component={ClientLogin} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/signup",
    element: <MainContainer component={ClientSignUp} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/practitioner-signup",
    element: <MainContainer component={PractitionerSignUp} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/practitioner-login",
    element: <MainContainer component={PractitionerLogin} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/get-matched",
    element: <MainContainer component={GetStarted} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/browse-practitioners",
    element: <MainContainer component={Practitioners} />,
    errorElement: <ErrorPage />,
  },
  /*{
    path: "/dashboard",
    element: <MainContainer component={Dashboard} isProtected={true} />,
    errorElement: <ErrorPage />,
  },*/
  {
    path: "/admin/dashboard",
    element: (
      <MainContainer component={AdminDashboardHome} isProtected={true} />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/coach/dashboard",
    element: (
      <MainContainer component={AdminDashboardHome} isProtected={true} />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/coach/management",
    element: <MainContainer component={CoachSettings} isProtected={true} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/approvals",
    element: (
      <MainContainer component={AdminDashboardApproval} isProtected={true} />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/document-approvals",
    element: (
      <MainContainer
        component={AdminDashboardDocumentApproval}
        isProtected={true}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/practitioner/list",
    element: (
      <MainContainer component={AdminPractitionerList} isProtected={true} />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/user/list",
    element: <MainContainer component={AdminUserList} isProtected={true} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/client/dashboard",
    element: (
      <MainContainer
        component={ClientDashboardHome}
        isProtected={true}
        role={"client"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/client/messages",
    element: (
      <MainContainer
        component={ClientMessages}
        isProtected={true}
        role={"client"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/practitioner/dashboard",
    element: (
      <MainContainer
        component={PractitionerDashboardHome}
        isProtected={true}
        role={"practitioner"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/practitioner/calendar",
    element: (
      <MainContainer
        component={PractitionerCalendar}
        isProtected={true}
        role={"practitioner"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/practitioner/profile",
    element: (
      <MainContainer
        component={PractitionerProfile}
        isProtected={true}
        role={"practitioner"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/practitioner/certs",
    element: (
      <MainContainer
        component={PractitionerCerts}
        isProtected={true}
        role={"practitioner"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/practitioner/profilepage/:id",
    element: (
      <MainContainer
        component={PractitionerProfilePage}
        isProtected={false}
        role={"practitioner"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/practitioner/profilepage/:id",
    element: (
      <MainContainer
        component={AdminDashboardPractitionerProfile}
        isProtected={false}
        // role={"practitioner"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/practitioner/settings",
    element: (
      <MainContainer
        component={PractitionerSettings}
        isProtected={true}
        role={"practitioner"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/practitioner/messages",
    element: (
      <MainContainer
        component={PractitionerMessages}
        isProtected={true}
        role={"practitioner"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/practitioner/clients",
    element: (
      <MainContainer
        component={PractitionerClients}
        isProtected={true}
        role={"practitioner"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/client/bookcalendar/:id",
    element: (
      <MainContainer
        component={ClientBookCalendar}
        isProtected={false}
        role={"client"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/client/sessions",
    element: (
      <MainContainer
        component={ClientSessions}
        isProtected={true}
        role={"client"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/client/profile",
    element: (
      <MainContainer
        component={ClientProfile}
        isProtected={true}
        role={"client"}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/faq",
    element: (
      <MainContainer component={Faq} isProtected={false} role={"client"} />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/book-practitioner",
    element: <MainContainer component={BookPractitioner} />,
    errorElement: <ErrorPage />,
  },
]);

export function Routing() {
  return <RouterProvider router={router}></RouterProvider>;
}

export default Routing;
